import React, { useState } from "react";
import { Link } from "react-router-dom";

import "./datenschutz.css";
import "./new_css/nav.css";

const Datenschutz = (props) => {
  const [openNav, setOpenNav] = useState(false);

  const handleNavOpen = () => {
    setOpenNav(!openNav);
  };
  return (
    <div className="datenschutz-container">
      <div className="home-header">
        <header data-thq="thq-navbar" className="home-navbar-interactive">
          <div className="logoHeaderContainer">
            <div className="home-container01">
              <Link to="/" className="home-navlink">
                <img
                  alt="image"
                  src="/1585861357%20(2)-1500h.png"
                  className="home-image"
                />
              </Link>
            </div>
            <span className="home-text">Dienstleistungen Südwest</span>
          </div>
          <div className="home-container02">
            <div data-thq="thq-navbar-nav" className="home-desktop-menu">
              <nav className="home-links">
                <a href="#Home" className="home-link">
                  Home
                </a>
                <a
                  href="https://dienstleistungen-suedwest.de/#Dienstleistungen"
                  className="home-link1"
                >
                  Dienstleistungen
                </a>
                <a
                  href="https://dienstleistungen-suedwest.de/#Referenzen"
                  className="home-link2"
                >
                  Referenzen
                </a>
                <a
                  href="https://dienstleistungen-suedwest.de/#Über%20uns"
                  className="home-link3"
                >
                  Über uns
                </a>
                <a
                  href="https://dienstleistungen-suedwest.de/#Kontakt"
                  className="home-link4"
                >
                  Kontakt
                </a>
              </nav>
              <div className="home-buttons">
                <a
                  href="https://jobs.dienstleistungen"
                  className="home-link5 button"
                >
                  <span className="home-text001">
                    <span>Stellenauschreibung</span>
                    <br></br>
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div
            data-thq="thq-burger-menu"
            className="home-burger-menu"
            onClick={() => handleNavOpen()}
          >
            <svg viewBox="0 0 1024 1024" className="home-icon">
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>

          {openNav ? (
            <div data-thq="thq-mobile-menu" className="home-mobile-menu">
              <div className="home-nav">
                <div className="home-top">
                  <img
                    alt="image"
                    src="/1585861357%20(2)-1500h.png"
                    className="home-logo"
                  />
                  <div
                    data-thq="thq-close-menu"
                    className="home-close-menu"
                    onClick={() => handleNavOpen()}
                  >
                    <svg viewBox="0 0 1024 1024" className="home-icon02">
                      <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                    </svg>
                  </div>
                </div>
                <nav className="home-links1">
                  <a
                    href="/"
                    onClick={() => handleNavOpen()}
                    className="home-link"
                  >
                    Home
                  </a>
                  <a
                    href="https://dienstleistungen-suedwest.de/#Dienstleistungen"
                    onClick={() => handleNavOpen()}
                    className="home-link1"
                  >
                    Dienstleistungen
                  </a>
                  <a
                    href="https://dienstleistungen-suedwest.de/#Referenzen"
                    onClick={() => handleNavOpen()}
                    className="home-link2"
                  >
                    Referenzen
                  </a>
                  <a
                    href="https://dienstleistungen-suedwest.de/#Über%20uns"
                    onClick={() => handleNavOpen()}
                    className="home-link3"
                  >
                    Über uns
                  </a>
                  <a
                    href="https://dienstleistungen-suedwest.de/#Kontakt"
                    onClick={() => handleNavOpen()}
                    className="home-link4"
                  >
                    Kontakt
                  </a>
                </nav>
                <div className="home-buttons">
                  <a
                    href="https://jobs.dienstleistungen"
                    className="home-link5 button"
                  >
                    <span className="home-text001">
                      <span>Stellenauschreibung</span>
                      <br></br>
                    </span>
                  </a>
                </div>
              </div>
              <div className="nav-socials">
                <svg
                  viewBox="0 0 950.8571428571428 1024"
                  className="home-icon04"
                >
                  <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                </svg>
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  className="home-icon06"
                >
                  <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                </svg>
                <svg
                  viewBox="0 0 602.2582857142856 1024"
                  className="home-icon08"
                >
                  <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
                </svg>
              </div>
            </div>
          ) : null}
        </header>
      </div>
      <div className="datenschutz-container3"></div>
      <div className="datenschutz-container4">
        <span className="datenschutz-text008">Datenschutzerklärung</span>
        <span>
          <span className="datenschutz-text010">
            1. Datenschutz auf einen Blick
          </span>
          <br className="datenschutz-text011"></br>
          <br></br>
          <span className="datenschutz-text013">Allgemeine Hinweise</span>
          <br className="datenschutz-text014"></br>
          <br></br>
          <span>
            Die folgenden Hinweise geben einen einfachen Überblick darüber, was
            mit Ihren personenbezogenen Daten 
          </span>
          <span>
            passiert, wenn Sie diese Website besuchen. Personenbezogene Daten
            sind alle Daten, mit denen Sie
            <span
              dangerouslySetInnerHTML={{
                __html: " ",
              }}
            />
          </span>
          <span>
            persönlich identifiziert werden können. Ausführliche Informationen
            zum Thema Datenschutz entnehmen
          </span>
          <br></br>
          <span>
            Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.
          </span>
          <br></br>
          <br></br>
          <span className="datenschutz-text023">
            1. Datenerfassung auf dieser Website
          </span>
          <br></br>
          <br></br>
          <span className="datenschutz-text026">
            Wer ist verantwortlich für die Datenerfassung auf dieser Website?
          </span>
          <br className="datenschutz-text027"></br>
          <br></br>
          <span>
            Die Datenverarbeitung auf dieser Website erfolgt durch den
            Websitebetreiber. Dessen Kontaktdaten 
          </span>
          <span>
            können Sie dem Abschnitt „Hinweis zur Verantwortlichen Stelle“ in
            dieser Datenschutzerklärung entnehmen.
          </span>
          <br></br>
          <br></br>
          <span className="datenschutz-text033">
            Wie erfassen wir Ihre Daten?
          </span>
          <br className="datenschutz-text034"></br>
          <br></br>
          <span>
            Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
            mitteilen. Hierbei kann es sich z. B. um 
          </span>
          <span>Daten handeln, die Sie in ein Kontaktformular eingeben.</span>
          <br></br>
          <br></br>
          <span>
            Andere Daten werden automatisch oder nach Ihrer Einwilligung beim
            Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor
            allem technische Daten (z. B. Internetbrowser, Betriebssystem oder
            Uhrzeit 
          </span>
          <span>
            des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch,
            sobald Sie diese Website betreten.
          </span>
          <br></br>
          <br></br>
          <span className="datenschutz-text044">
            Wofür nutzen wir Ihre Daten?
          </span>
          <br className="datenschutz-text045"></br>
          <br></br>
          <span>
            Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
            der Website zu gewährleisten. Andere 
          </span>
          <span>
            Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.
          </span>
          <br></br>
          <br></br>
          <span className="datenschutz-text051">
            Welche Rechte haben Sie bezüglich Ihrer Daten?
          </span>
          <br className="datenschutz-text052"></br>
          <br></br>
          <span>
            Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft,
            Empfänger und Zweck Ihrer 
          </span>
          <span>
            gespeicherten personenbezogenen Daten zu erhalten. Sie haben
            außerdem ein Recht, die Berichtigung oder 
          </span>
          <span>
            Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur
            Datenverarbeitung erteilt haben, 
          </span>
          <span>
            können Sie diese Einwilligung jederzeit für die Zukunft widerrufen.
            Außerdem haben Sie das Recht, unter 
          </span>
          <span>
            bestimmten Umständen die Einschränkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen.
          </span>
          <br></br>
          <span>
            Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen
            Aufsichtsbehörde zu.
          </span>
          <br></br>
          <br></br>
          <span>
            Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie
            sich jederzeit an uns wenden.
          </span>
          <br></br>
          <br></br>
          <span className="datenschutz-text066">
            Analyse-Tools und Tools von Drittanbietern
          </span>
          <br className="datenschutz-text067"></br>
          <br></br>
          <span>
            Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch
            ausgewertet werden. Das geschieht vor 
          </span>
          <span>allem mit sogenannten Analyseprogrammen.</span>
          <br></br>
          <br></br>
          <span>
            Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in
            der folgenden 
          </span>
          <span>Datenschutzerklärung.</span>
          <br></br>
          <br></br>
          <span className="datenschutz-text077">2. Hosting</span>
          <br className="datenschutz-text078"></br>
          <br></br>
          <span>
            Wir hosten die Inhalte unserer Website bei folgendem Anbieter:
          </span>
          <br></br>
          <br></br>
          <span className="datenschutz-text083">Hetzner</span>
          <br className="datenschutz-text084"></br>
          <br></br>
          <span>
            Anbieter ist die Hetzner Online GmbH, Industriestr. 25, 91710
            Gunzenhausen (nachfolgend Hetzner).
          </span>
          <br></br>
          <br></br>
          <span>
            Details entnehmen Sie der Datenschutzerklärung von Hetzner:
          </span>
          <br></br>
        </span>
        <a
          href="https://www.hetzner.com/de/rechtliches/datenschutz"
          target="_blank"
          rel="noreferrer noopener"
          className="datenschutz-link6"
        >
          https://www.hetzner.com/de/rechtliches/datenschutz
        </a>
        <span>
          <br></br>
          <span>
            Die Verwendung von Hetzner erfolgt auf Grundlage von Art. 6 Abs. 1
            lit. f DSGVO. Wir haben ein berechtigtes Interesse an einer
            möglichst zuverlässigen Darstellung unserer Website. Sofern eine
            entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
            ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25
            Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies
            oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B.
            Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung
            ist jederzeit widerrufbar.
          </span>
          <br></br>
          <br></br>
          <span className="datenschutz-text096">Auftragsverarbeitung</span>
          <br></br>
          <br></br>
          <span>
            Wir haben einen Vertrag über Auftragsverarbeitung (AVV) zur Nutzung
            des oben genannten Dienstes geschlossen. Hierbei handelt es sich um
            einen datenschutzrechtlich vorgeschriebenen Vertrag, der
            gewährleistet, dass dieser die personenbezogenen Daten unserer
            Websitebesucher nur nach unseren  Weisungen und unter Einhaltung der
            DSGVO verarbeitet.
          </span>
          <br></br>
          <br></br>
          <span className="datenschutz-text102">
            3. Allgemeine Hinweise und Pflichtinformationen
          </span>
          <br></br>
          <br></br>
          <span className="datenschutz-text105">Datenschutz</span>
          <br></br>
          <br></br>
          <span>
            Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen
            Daten sehr ernst. Wir behandeln Ihre 
          </span>
          <span>
            personenbezogenen Daten vertraulich und entsprechend den
            gesetzlichen Datenschutzvorschriften sowie 
          </span>
          <span>dieser Datenschutzerklärung.</span>
          <br></br>
          <br></br>
          <span>
            Wenn Sie diese Website benutzen, werden verschiedene
            personenbezogene Daten erhoben. 
          </span>
          <span>
            Personenbezogene Daten sind Daten, mit denen Sie persönlich
            identifiziert werden können. Die vorliegende 
          </span>
          <span>
            Datenschutzerklärung erläutert, welche Daten wir erheben und wofür
            wir sie nutzen. Sie erläutert auch, wie 
          </span>
          <span>und zu welchem Zweck das geschieht.</span>
          <br></br>
          <br></br>
          <span>
            Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B.
            bei der Kommunikation per E-Mail) 
          </span>
          <span>
            Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten
            vor dem Zugriff durch Dritte ist nicht 
          </span>
          <span>möglich.</span>
          <br></br>
          <br></br>
          <span className="datenschutz-text124">
            Hinweis zur verantwortlichen Stelle
          </span>
          <br></br>
          <br></br>
          <span>
            Die verantwortliche Stelle für die Datenverarbeitung auf dieser
            Website ist:
          </span>
          <br></br>
          <br></br>
          <span className="datenschutz-text130">Dienstleistungen-Südwest</span>
          <br className="datenschutz-text131"></br>
          <span>Samia Roth</span>
          <br></br>
          <span>Im Wöhr 3</span>
          <br></br>
          <span>76437 Rastatt</span>
          <br></br>
          <br className="datenschutz-text138"></br>
          <br></br>
          <span>Telefon: +49 (0)7222 15 88 361</span>
          <br></br>
          <span>E-Mail: kontakt@dienstleistungen-suedwest.de</span>
          <br></br>
          <br></br>
          <span>
            Verantwortliche Stelle ist die natürliche oder juristische Person,
            die allein oder gemeinsam mit anderen über 
          </span>
          <span>
            die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten
            (z. B. Namen, E-Mail-Adressen o. Ä.) 
          </span>
          <span>entscheidet.</span>
          <br></br>
          <br></br>
          <span className="datenschutz-text150">Speicherdauer</span>
          <br></br>
          <br></br>
          <span>
            Soweit innerhalb dieser Datenschutzerklärung keine speziellere
            Speicherdauer genannt wurde, verbleiben 
          </span>
          <span>
            Ihre personenbezogenen Daten bei uns, bis der Zweck für die
            Datenverarbeitung entfällt. Wenn Sie ein 
          </span>
          <span>
            berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur
            Datenverarbeitung widerrufen, 
          </span>
          <span>
            werden Ihre Daten gelöscht, sofern wir keine anderen rechtlich
            zulässigen Gründe für die Speicherung Ihrer 
          </span>
          <span>
            personenbezogenen Daten haben (z. B. steuer- oder handelsrechtliche
            Aufbewahrungsfristen); im 
          </span>
          <span>
            letztgenannten Fall erfolgt die Löschung nach Fortfall dieser
            Gründe. 
          </span>
          <br></br>
          <br></br>
          <span className="datenschutz-text161">
            Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung
            auf dieser 
          </span>
          <span className="datenschutz-text162">Website</span>
          <br className="datenschutz-text163"></br>
          <br></br>
          <span>
            Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten
            wir Ihre personenbezogenen Daten auf 
          </span>
          <span>
            Grundlage von Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a
            DSGVO, sofern besondere Datenkategorien 
          </span>
          <span>
            nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle einer
            ausdrücklichen Einwilligung in die Übertragung 
          </span>
          <span>
            personenbezogener Daten in Drittstaaten erfolgt die
            Datenverarbeitung außerdem auf Grundlage von Art. 
          </span>
          <span>
            49 Abs. 1 lit. a DSGVO. Sofern Sie in die Speicherung von Cookies
            oder in den Zugriff auf Informationen in 
          </span>
          <span>
            Ihr Endgerät (z. B. via Device-Fingerprinting) eingewilligt haben,
            erfolgt die Datenverarbeitung zusätzlich 
          </span>
          <span>
            auf Grundlage von § 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit
            widerrufbar. Sind Ihre Daten zur 
          </span>
          <span>
            Vertragserfüllung oder zur Durchführung vorvertraglicher Maßnahmen
            erforderlich, verarbeiten wir Ihre 
          </span>
          <span>
            Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des Weiteren
            verarbeiten wir Ihre Daten, sofern diese 
          </span>
          <span>
            zur Erfüllung einer rechtlichen Verpflichtung erforderlich sind auf
            Grundlage von Art. 6 Abs. 1 lit. c DSGVO. 
          </span>
          <span>
            Die Datenverarbeitung kann ferner auf Grundlage unseres berechtigten
            Interesses nach Art. 6 Abs. 1 lit. f 
          </span>
          <span>
            DSGVO erfolgen. Über die jeweils im Einzelfall einschlägigen
            Rechtsgrundlagen wird in den folgenden 
          </span>
          <span>Absätzen dieser Datenschutzerklärung informiert.</span>
          <br></br>
          <br></br>
          <span className="datenschutz-text180">
            Empfänger von personenbezogenen Daten
          </span>
          <br className="datenschutz-text181"></br>
          <br></br>
          <span>
            Im Rahmen unserer Geschäftstätigkeit arbeiten wir mit verschiedenen
            externen Stellen zusammen. Dabei 
          </span>
          <span>
            ist teilweise auch eine Übermittlung von personenbezogenen Daten an
            diese externen Stellen erforderlich. 
          </span>
          <span>
            Wir geben personenbezogene Daten nur dann an externe Stellen weiter,
            wenn dies im Rahmen einer 
          </span>
          <span>
            Vertragserfüllung erforderlich ist, wenn wir gesetzlich hierzu
            verpflichtet sind (z. B. Weitergabe von Daten 
          </span>
          <span>
            an Steuerbehörden), wenn wir ein berechtigtes Interesse nach Art. 6
            Abs. 1 lit. f DSGVO an der Weitergabe 
          </span>
          <span>
            haben oder wenn eine sonstige Rechtsgrundlage die Datenweitergabe
            erlaubt. Beim Einsatz von 
          </span>
          <span>
            Auftragsverarbeitern geben wir personenbezogene Daten unserer Kunden
            nur auf Grundlage eines gültigen 
          </span>
          <span>
            Vertrags über Auftragsverarbeitung weiter. Im Falle einer
            gemeinsamen Verarbeitung wird ein Vertrag über 
          </span>
          <span>gemeinsame Verarbeitung geschlossen.</span>
          <br></br>
          <br></br>
          <span className="datenschutz-text194">
            Widerruf Ihrer Einwilligung zur Datenverarbeitung
          </span>
          <br className="datenschutz-text195"></br>
          <br></br>
          <span>
            Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
            Einwilligung möglich. Sie können eine 
          </span>
          <span>
            bereits erteilte Einwilligung jederzeit widerrufen. Die
            Rechtmäßigkeit der bis zum Widerruf erfolgten 
          </span>
          <span>Datenverarbeitung bleibt vom Widerruf unberührt.</span>
          <br></br>
          <br></br>
          <span className="datenschutz-text202">
            Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie
            gegen 
          </span>
          <span className="datenschutz-text203">
            Direktwerbung (Art. 21 DSGVO)
          </span>
          <br className="datenschutz-text204"></br>
          <br></br>
          <span>
            WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E
            ODER F DSGVO 
          </span>
          <span>
            ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS
            IHRER BESONDEREN 
          </span>
          <span>
            SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN
            DATEN 
          </span>
          <span>
            WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE
            BESTIMMUNGEN GESTÜTZTES 
          </span>
          <span>
            PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE
            VERARBEITUNG BERUHT, 
          </span>
          <span>
            ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH
            EINLEGEN, 
          </span>
          <span>
            WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR
            VERARBEITEN, ES 
          </span>
          <span>
            SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE
            VERARBEITUNG 
          </span>
          <span>
            NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN
            ODER DIE 
          </span>
          <span>
            VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG
            VON 
          </span>
          <span>
            RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO). 
          </span>
          <span>
            WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU
            BETREIBEN, 
          </span>
          <span>
            SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG
            SIE 
          </span>
          <span>
            BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG 
          </span>
          <span>
            EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER
            DIREKTWERBUNG IN 
          </span>
          <span>
            VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE
            PERSONENBEZOGENEN DATEN 
          </span>
          <span>
            ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET
            (WIDERSPRUCH 
          </span>
          <span>NACH ART. 21 ABS. 2 DSGVO).</span>
          <br></br>
          <br></br>
          <span className="datenschutz-text226">
            Beschwerderecht bei der zuständigen Aufsichtsbehörde
          </span>
          <br className="datenschutz-text227"></br>
          <br></br>
          <span>
            Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
            Beschwerderecht bei einer 
          </span>
          <span>
            Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres
            gewöhnlichen Aufenthalts, ihres Arbeitsplatzes 
          </span>
          <span>
            oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht
            besteht unbeschadet anderweitiger 
          </span>
          <span>verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.</span>
          <br></br>
          <br></br>
          <span className="datenschutz-text235">
            Recht auf Datenübertragbarkeit
          </span>
          <br className="datenschutz-text236"></br>
          <br></br>
          <span>
            Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
            oder in Erfüllung eines Vertrags 
          </span>
          <span>
            automatisiert verarbeiten, an sich oder an einen Dritten in einem
            gängigen, maschinenlesbaren Format 
          </span>
          <span>
            aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten
            an einen anderen Verantwortlichen 
          </span>
          <span>
            verlangen, erfolgt dies nur, soweit es technisch machbar ist.
          </span>
          <br></br>
          <br></br>
          <span className="datenschutz-text244">
            Auskunft, Berichtigung und Löschung
          </span>
          <br className="datenschutz-text245"></br>
          <br></br>
          <span>
            Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
            jederzeit das Recht auf unentgeltliche 
          </span>
          <span>
            Auskunft über Ihre gespeicherten personenbezogenen Daten, deren
            Herkunft und Empfänger und den 
          </span>
          <span>
            Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder
            Löschung dieser Daten. Hierzu sowie 
          </span>
          <span>
            zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich
            jederzeit an uns wenden.
          </span>
          <br></br>
          <br></br>
          <span className="datenschutz-text253">
            Recht auf Einschränkung der Verarbeitung
          </span>
          <br className="datenschutz-text254"></br>
          <br></br>
          <span>
            Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen. 
          </span>
          <span>
            Hierzu können Sie sich jederzeit an uns wenden. Das Recht auf
            Einschränkung der Verarbeitung besteht in 
          </span>
          <span>folgenden Fällen:</span>
          <br></br>
        </span>
        <ul className="datenschutz-ul list">
          <li className="list-item">
            <span>
              Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
              personenbezogenen Daten bestreiten, benötigen wir in der Regel
              Zeit, um dies zu überprüfen. Für die  Dauer der Prüfung haben Sie
              das Recht, die Einschränkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen.
            </span>
          </li>
          <li className="list-item">
            <span>
              Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
              geschah/geschieht, können Sie statt der Löschung die Einschränkung
              der Datenverarbeitung verlangen.
            </span>
          </li>
          <li className="list-item">
            <span>
              Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie
              sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von
              Rechtsansprüchen benötigen, haben Sie das Recht, statt der
              Löschung die Einschränkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen.
            </span>
          </li>
          <li className="list-item">
            <span>
              Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt
              haben, muss eine Abwägung zwischen Ihren und unseren Interessen
              vorgenommen werden. Solange noch nicht feststeht, wessen
              Interessen überwiegen, haben Sie das Recht, die Einschränkung der
              Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
            </span>
          </li>
        </ul>
        <span>
          <span>
            Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
            eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung
            abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung,
            Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der
            Rechte einer anderen natürlichen oder juristischen Person oder aus
            Gründen eines wichtigen öffentlichen Interesses der Europäischen
            Union oder eines Mitgliedstaats verarbeitet werden.
          </span>
          <br></br>
          <br></br>
          <span className="datenschutz-text268">
            SSL- bzw. TLS-Verschlüsselung
          </span>
          <br></br>
          <br></br>
          <span>
            Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
            Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
            oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-
            bzw. TLSVerschlüsselung. Eine verschlüsselte Verbindung erkennen Sie
            daran, dass die Adresszeile des Browsers von „http://“ auf
            „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
          </span>
          <br></br>
          <br></br>
          <span>
            Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
            Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
            werden.
          </span>
          <br></br>
          <br></br>
          <span className="datenschutz-text277">
            4. Datenerfassung auf dieser Website
          </span>
          <br className="datenschutz-text278"></br>
          <br></br>
          <span className="datenschutz-text280">Cookies</span>
          <br></br>
          <br></br>
          <span>
            Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind
            kleine Datenpakete und richten auf Ihrem Endgerät keinen Schaden an.
            Sie werden entweder vorübergehend für die Dauer einer Sitzung
            (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem
            Endgerät gespeichert. Session-Cookies werden nach Ende Ihres Besuchs
            automatisch gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät
            gespeichert, bis Sie diese selbst löschen oder eine automatische
            Löschung durch Ihren Webbrowser erfolgt.
          </span>
          <br></br>
          <br></br>
          <span>
            Cookies können von uns (First-Party-Cookies) oder von
            Drittunternehmen stammen (sog. Third-PartyCookies).
            Third-Party-Cookies ermöglichen die Einbindung bestimmter
            Dienstleistungen von Drittunternehmen innerhalb von Webseiten (z. B.
            Cookies zur Abwicklung von Zahlungsdienstleistungen).
          </span>
          <br></br>
          <br></br>
          <span>
            Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind
            technisch notwendig, da bestimmte Webseitenfunktionen ohne diese
            nicht funktionieren würden (z. B. die Warenkorbfunktion oder die
            Anzeige von Videos). Andere Cookies können zur Auswertung des
            Nutzerverhaltens oder zu Werbezwecken verwendet werden.
          </span>
          <br></br>
          <br></br>
          <span>
            Cookies, die zur Durchführung des elektronischen
            Kommunikationsvorgangs, zur Bereitstellung bestimmter, von Ihnen
            erwünschter Funktionen (z. B. für die Warenkorbfunktion) oder zur
            Optimierung der Website (z. B. Cookies zur Messung des Webpublikums)
            erforderlich sind (notwendige Cookies), werden auf Grundlage von
            Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern keine andere
            Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein
            berechtigtes Interesse an der Speicherung von notwendigen Cookies
            zur technisch fehlerfreien und optimierten Bereitstellung seiner
            Dienste. Sofern eine Einwilligung zur Speicherung von Cookies und
            vergleichbaren Wiedererkennungstechnologien abgefragt wurde, erfolgt
            die Verarbeitung ausschließlich auf Grundlage dieser Einwilligung
            (Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG); die Einwilligung
            ist jederzeit widerrufbar.
          </span>
          <br></br>
          <br></br>
          <span>
            Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
            Cookies informiert werden und Cookies nur im Einzelfall erlauben,
            die Annahme von Cookies für bestimmte Fälle oder generell
            ausschließen sowie das automatische Löschen der Cookies beim
            Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies
            kann die Funktionalität dieser Website eingeschränkt sein.
            <span
              dangerouslySetInnerHTML={{
                __html: " ",
              }}
            />
          </span>
          <br></br>
          <br></br>
          <span>
            Welche Cookies und Dienste auf dieser Website eingesetzt werden,
            können Sie dieser Datenschutzerklärung entnehmen.
          </span>
          <br></br>
          <br></br>
          <span className="datenschutz-text301">Kontaktformular</span>
          <br></br>
          <br></br>
          <span>
            Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden
            Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort
            angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den
            Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir
            nicht ohne Ihre Einwilligung weiter.
          </span>
          <br></br>
          <br></br>
          <span>
            Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs.
            1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
            zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
            erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung
            auf unserem berechtigten Interesse an der effektiven Bearbeitung der
            an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf
            Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese
            abgefragt wurde; die Einwilligung ist jederzeit widerrufbar.
          </span>
          <br></br>
          <br></br>
          <span>
            Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei
            uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
            Speicherung widerrufen oder der Zweck für die Datenspeicherung
            entfällt (z. B. nach abgeschlossener Bearbeitung Ihrer Anfrage).
            Zwingende gesetzliche Bestimmungen – insbesondere
            Aufbewahrungsfristen – bleiben unberührt.
          </span>
          <br></br>
          <br></br>
          <span className="datenschutz-text313">
            Anfrage per E-Mail, Telefon oder Telefax
          </span>
          <br></br>
          <br></br>
          <span>
            Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird
            Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen
            Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei
            uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne
            Ihre Einwilligung weiter.
          </span>
          <br></br>
          <br></br>
          <span>
            Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs.
            1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
            zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
            erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung
            auf unserem berechtigten Interesse an der effektiven Bearbeitung der
            an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf
            Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese
            abgefragt wurde; die Einwilligung ist jederzeit widerrufbar.
          </span>
          <br></br>
          <br></br>
          <span>
            Die von Ihnen an uns per Kontaktanfragen übersandten Daten
            verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre
            Einwilligung zur Speicherung widerrufen oder der Zweck für die
            Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung
            Ihres Anliegens). Zwingende gesetzliche Bestimmungen – insbesondere
            gesetzliche Aufbewahrungsfristen – bleiben unberührt.
          </span>
          <br></br>
          <br></br>
          <span className="datenschutz-text325">
            5. Präsenzen in sozialen Netzwerken (Social Media)
          </span>
          <br className="datenschutz-text326"></br>
          <br></br>
          <span>
            Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und
            verarbeiten in diesem Rahmen Daten der Nutzer, um mit den dort
            aktiven Nutzern zu kommunizieren oder um Informationen über uns
            anzubieten.
          </span>
          <br></br>
          <br></br>
          <span>
            Wir weisen darauf hin, dass dabei Daten der Nutzer außerhalb des
            Raumes der Europäischen Union verarbeitet werden können. Hierdurch
            können sich für die Nutzer Risiken ergeben, weil so z. B. die
            Durchsetzung der Rechte der Nutzer erschwert werden könnte.
          </span>
          <br></br>
          <br></br>
          <span>
            Ferner werden die Daten der Nutzer innerhalb sozialer Netzwerke im
            Regelfall für Marktforschungs- und Werbezwecke verarbeitet. So
            können z. B. anhand des Nutzungsverhaltens und sich daraus
            ergebender Interessen der Nutzer Nutzungsprofile erstellt werden.
            Die Nutzungsprofile können wiederum verwendet werden, um z. B.
            Werbeanzeigen innerhalb und außerhalb der Netzwerke zu schalten, die
            mutmaßlich den Interessen der Nutzer entsprechen. Zu diesen Zwecken
            werden im Regelfall Cookies auf den Rechnern der Nutzer gespeichert,
            in denen das Nutzungsverhalten und die Interessen der Nutzer
            gespeichert werden. Ferner können in den Nutzungsprofilen auch Daten
            unabhängig der von den Nutzern verwendeten Geräte gespeichert werden
            (insbesondere, wenn die Nutzer Mitglieder der jeweiligen Plattformen
            sind und bei diesen eingeloggt sind).
          </span>
          <br></br>
          <br></br>
          <span>
            Für eine detaillierte Darstellung der jeweiligen Verarbeitungsformen
            und der Widerspruchsmöglichkeiten (Opt-Out) verweisen wir auf die
            Datenschutzerklärungen und Angaben der Betreiber der jeweiligen
            Netzwerke.
          </span>
          <br></br>
          <br></br>
          <span>
            Auch im Fall von Auskunftsanfragen und der Geltendmachung von
            Betroffenenrechten weisen wir darauf hin, dass diese am effektivsten
            bei den Anbietern geltend gemacht werden können. Nur die Anbieter
            haben jeweils Zugriff auf die Daten der Nutzer und können direkt
            entsprechende Maßnahmen ergreifen und Auskünfte geben. Sollten Sie
            dennoch Hilfe benötigen, dann können Sie sich an uns wenden.
          </span>
          <br></br>
        </span>
        <ul className="datenschutz-ul1 list">
          <li className="list-item">
            <span>
              <span className="datenschutz-text343">
                Verarbeitete Datenarten:
              </span>
              <span className="datenschutz-text344"> Kontaktdaten</span>
              <span>
                {" "}
                (z. B. E-Mail, Telefonnummern); Inhaltsdaten (z. B. Eingaben in
                Onlineformularen); Nutzungsdaten (z. B. besuchte Webseiten,
                Interesse an Inhalten, Zugriffszeiten); Meta-, Kommunikations-
                und Verfahrensdaten (z. .B. IP-Adressen, Zeitangaben,
                Identifikationsnummern, Einwilligungsstatus).
              </span>
            </span>
          </li>
          <li className="list-item">
            <span>
              <span className="datenschutz-text347">Betroffene Personen:</span>
              <span>
                {" "}
                Nutzer (z. .B. Webseitenbesucher, Nutzer von Onlinediensten).
              </span>
            </span>
          </li>
          <li className="list-item">
            <span>
              <span className="datenschutz-text350">
                Zwecke der Verarbeitung:
              </span>
              <span>
                {" "}
                Kontaktanfragen und Kommunikation; Feedback (z. B. Sammeln von
                Feedback via Online-Formular). Marketing. 
              </span>
              <span>
                Rechtsgrundlagen: Berechtigte Interessen (Art. 6 Abs. 1 S. 1
                lit. f) DSGVO).
              </span>
            </span>
          </li>
          <li className="list-item">
            <span>
              <span className="datenschutz-text354">Rechtsgrundlagen:</span>
              <span>
                {" "}
                Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
              </span>
            </span>
          </li>
        </ul>
        <span className="datenschutz-text356">
          Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
        </span>
        <ul className="datenschutz-ul2 list">
          <li className="list-item">
            <span>
              <span className="datenschutz-text358">Instagram:</span>
              <span>
                {" "}
                Soziales Netzwerk;
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span className="datenschutz-text360">Dienstanbieter:</span>
              <span>
                {" "}
                Meta Platforms Ireland Limited, Merrion Road, Dublin 4, D04
                X2K5, Irland;
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span className="datenschutz-text362">Rechtsgrundlagen:</span>
              <span>
                {" "}
                Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO);
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span className="datenschutz-text364">Website:</span>
              <span>
                {" "}
                https://www.instagram.com;
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span className="datenschutz-text366">Datenschutzerklärung:</span>
              <span>
                {" "}
                https://instagram.com/about/legal/privacy.
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span className="datenschutz-text368">
                Grundlage Drittlandtransfers:
              </span>
              <span> Data Privacy Framework (DPF).</span>
            </span>
          </li>
        </ul>
      </div>
      <footer className="home-footer">
        <img
          alt="logo"
          src="/1585861357%20(2)-1500h.png"
          className="home-image12"
        />
        <Link to="/impressum" className="home-navlink6">
          <span>Impressum</span>
          <br></br>
        </Link>
        <Link to="/datenschutz" className="home-navlink6">
          <span>Datenschutz</span>
          <br></br>
        </Link>
        <div className="home-icon-group1">
          <a
            href="https://www.instagram.com/dienstleistungen_suedwest/"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link7"
          >
            <svg viewBox="0 0 877.7142857142857 1024" className="home-icon10">
              <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
            </svg>
          </a>
        </div>
      </footer>
    </div>
  );
};

export default Datenschutz;
