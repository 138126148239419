import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";

import "./grabpflege.css";
import "./new_css/grabpflege.css";
import "./new_css/nav.css";

const Grabpflege = (props) => {
  const [openNav, setOpenNav] = useState(false);

  const handleNavOpen = () => {
    setOpenNav(!openNav);
  };
  return (
    <div className="grabpflege-container">
      <Helmet>
        <title>Grabpflege - Dienstleistungen Südwest</title>
        <meta
          property="og:title"
          content="Grabpflege - Dienstleistungen Südwest"
        />
      </Helmet>
      <div className="grabpflege-container1"></div>
      <div className="grabpflege-container2">
        <span className="grabpflege-text">Grabpflege</span>
        <img alt="image" src="/image.png" className="grabpflege-image" />
      </div>
      <div className="grabpflege-container3">
        <span className="grabpflege-text01">Unsere Leistungen</span>
        <span className="grabpflege-text02">
          Ehrendes, liebevolles Andenken
        </span>
        <span>
          <br></br>
          <span>
            Mit der Übernahme einer Grabstätte verpflichtet sich der Erwerber,
            für eine ordnungsgemäße Anlage und Pflege der Grabstätte Sorge zu
            tragen. Eine wichtige Dienstleistung, die wir, die Dienstleistungen
            Südwest, für Sie, in Ihrem Auftrag und nach Ihren Wünschen
            wahrnehmen. Ein mit Blumen geschmücktes und gepflegtes Grab ist
            Zeichen der Erinnerung und Symbol der Wertschätzung für einen
            Menschen, der einem nahe stand.
          </span>
          <br></br>
          <span>
            Aber was tun, wenn man in eine andere Stadt umzieht oder wenn man
            aus gesundheitlichen Gründen die Grabpflege nicht mehr selbst
            durchführen kann? Hier beginnt unsere Leistung für Sie. Mit
            individuellen Lösungen, die sich flexibel auf ihre Bedürfnisse
            zuschneiden lassen.
          </span>
          <br></br>
          <span>
            Neben der Jahresgrabpflegeerbringen wir sämtliche mit der Grabanlage
            zusammenhängenden Arbeiten, zum Beispiel Neuanlage, gärtnerische
            Umgestaltung, Schneiden von Heckeneinfassungen und Buchskugeln,
            Erneuerung der gärtnerischen Anlage nach Senkschäden. Individuelle
            Wünsche der Kunden werden selbstverständlich berücksichtigt.
          </span>
          <br></br>
          <br></br>
          <span>Die Grabpflege umfasst folgende Leistungen:</span>
          <br></br>
        </span>
        <ul className="grabpflege-ul list">
          <li className="list-item">
            <span>
              die gärtnerische Pflege, Auflockern des Bodens, Düngen, Gießen,
              Unkrautjäten, Laubbeseitigung.
            </span>
          </li>
          <li className="list-item">
            <span>
              jahreszeitlich wechselnde Bepflanzung mit Frühjahrs-, Sommer- und
              Herbstblumenschmuck.
            </span>
          </li>
          <li className="list-item">
            <span>
              Schmuck des Grabes mit Tannengrün zu Allerheiligen, Aufstellen von
              Grabschmuck und Grablaterne zu Allerheiligen, Totensonntag und
              persönlichen Gedenktagen.
            </span>
          </li>
          <li className="list-item">
            <span>Beseitigung von Senk- und Wildschäden.</span>
          </li>
          <li className="list-item">
            <span>100 % Plastikfreie Verwendung unserer Materialen</span>
          </li>
        </ul>
        <span>
          Vor dem Abschluss eines zeitlich überschaubaren Pflegevertrages steht
          die individuelle und kompetente Beratung der Kunden. Unser Unternehmen
          garantiert Qualität über die gesamte Laufzeit sowie eine transparente
          Angebots- beziehungsweise Rechnungserstellung
        </span>
      </div>
      <div className="home-header">
        <header data-thq="thq-navbar" className="home-navbar-interactive">
          <div className="logoHeaderContainer">
            <div className="home-container01">
              <Link to="/" className="home-navlink">
                <img
                  alt="image"
                  src="/1585861357%20(2)-1500h.png"
                  className="home-image"
                />
              </Link>
            </div>
            <span className="home-text">Dienstleistungen Südwest</span>
          </div>
          <div className="home-container02">
            <div data-thq="thq-navbar-nav" className="home-desktop-menu">
              <nav className="home-links">
                <a href="#Home" className="home-link">
                  Home
                </a>
                <a
                  href="https://dienstleistungen-suedwest.de/#Dienstleistungen"
                  className="home-link1"
                >
                  Dienstleistungen
                </a>
                <a
                  href="https://dienstleistungen-suedwest.de/#Referenzen"
                  className="home-link2"
                >
                  Referenzen
                </a>
                <a
                  href="https://dienstleistungen-suedwest.de/#Über%20uns"
                  className="home-link3"
                >
                  Über uns
                </a>
                <a
                  href="https://dienstleistungen-suedwest.de/#Kontakt"
                  className="home-link4"
                >
                  Kontakt
                </a>
              </nav>
              <div className="home-buttons">
                <a
                  href="https://jobs.dienstleistungen"
                  className="home-link5 button"
                >
                  <span className="home-text001">
                    <span>Stellenauschreibung</span>
                    <br></br>
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div
            data-thq="thq-burger-menu"
            className="home-burger-menu"
            onClick={() => handleNavOpen()}
          >
            <svg viewBox="0 0 1024 1024" className="home-icon">
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>

          {openNav ? (
            <div data-thq="thq-mobile-menu" className="home-mobile-menu">
              <div className="home-nav">
                <div className="home-top">
                  <img
                    alt="image"
                    src="/1585861357%20(2)-1500h.png"
                    className="home-logo"
                  />
                  <div
                    data-thq="thq-close-menu"
                    className="home-close-menu"
                    onClick={() => handleNavOpen()}
                  >
                    <svg viewBox="0 0 1024 1024" className="home-icon02">
                      <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                    </svg>
                  </div>
                </div>
                <nav className="home-links1">
                  <a
                    href="/"
                    onClick={() => handleNavOpen()}
                    className="home-link"
                  >
                    Home
                  </a>
                  <a
                    href="https://dienstleistungen-suedwest.de/#Dienstleistungen"
                    onClick={() => handleNavOpen()}
                    className="home-link1"
                  >
                    Dienstleistungen
                  </a>
                  <a
                    href="https://dienstleistungen-suedwest.de/#Referenzen"
                    onClick={() => handleNavOpen()}
                    className="home-link2"
                  >
                    Referenzen
                  </a>
                  <a
                    href="https://dienstleistungen-suedwest.de/#Über%20uns"
                    onClick={() => handleNavOpen()}
                    className="home-link3"
                  >
                    Über uns
                  </a>
                  <a
                    href="https://dienstleistungen-suedwest.de/#Kontakt"
                    onClick={() => handleNavOpen()}
                    className="home-link4"
                  >
                    Kontakt
                  </a>
                </nav>
                <div className="home-buttons">
                  <a
                    href="https://jobs.dienstleistungen"
                    className="home-link5 button"
                  >
                    <span className="home-text001">
                      <span>Stellenauschreibung</span>
                      <br></br>
                    </span>
                  </a>
                </div>
              </div>
              <div className="nav-socials">
                <svg
                  viewBox="0 0 950.8571428571428 1024"
                  className="home-icon04"
                >
                  <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                </svg>
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  className="home-icon06"
                >
                  <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                </svg>
                <svg
                  viewBox="0 0 602.2582857142856 1024"
                  className="home-icon08"
                >
                  <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
                </svg>
              </div>
            </div>
          ) : null}
        </header>
      </div>
      <footer className="home-footer">
        <img
          alt="logo"
          src="/1585861357%20(2)-1500h.png"
          className="home-image12"
        />
        <Link to="/impressum" className="home-navlink6">
          <span>Impressum</span>
          <br></br>
        </Link>
        <Link to="/datenschutz" className="home-navlink6">
          <span>Datenschutz</span>
          <br></br>
        </Link>
        <div className="home-icon-group1">
          <a
            href="https://www.instagram.com/dienstleistungen_suedwest/"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link7"
          >
            <svg viewBox="0 0 877.7142857142857 1024" className="home-icon10">
              <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
            </svg>
          </a>
        </div>
      </footer>
    </div>
  );
};

export default Grabpflege;
