import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";

import "./gebudereinigung.css";
import "./new_css/gebudereinigung.css";
import "./new_css/nav.css";

const Gebudereinigung = (props) => {
  const [openNav, setOpenNav] = useState(false);

  const handleNavOpen = () => {
    setOpenNav(!openNav);
  };

  return (
    <div className="gebudereinigung-container">
      <Helmet>
        <title>Gebudereinigung - Dienstleistungen Südwest</title>
        <meta
          property="og:title"
          content="Gebudereinigung - Dienstleistungen Südwest"
        />
      </Helmet>
      <div className="gebudereinigung-container1"></div>
      <div className="gebudereinigung-gebudereinigung">
        <div className="gebudereinigung-container2">
          <div className="gebudereinigung-container3">
            <span className="gebudereinigung-text">Gebäudereinigung</span>
            <div className="gebudereinigung-container4">
              <span className="gebudereinigung-text001">
                <span>
                  Mit unserem professionellem Gebäudemanagement und unserem
                  geschulten Fachpersonal decken wir alle Reinigungsbereiche ab.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <br className="gebudereinigung-text003"></br>
                <span>
                  Selbst schwierige oder starke Verschmutzungen bekommen wir mit
                  unserer professionellen Reinigung und unseren Pflegemitteln
                  wieder hygienisch sauber.
                </span>
                <br className="gebudereinigung-text005"></br>
                <span>
                  Lernen Sie unsere Leistungen kennen und lassen Sie sich ein
                  unverbindliches Angebot geben.
                </span>
              </span>
            </div>
            <ul className="gebudereinigung-ul list">
              <li className="list-item">
                <a href="#Grundreinigung" className="gebudereinigung-link">
                  Grundreinigung
                </a>
              </li>
              <li className="list-item">
                <a href="#Glasreinigung" className="gebudereinigung-link01">
                  Glasreinigung innen und außen
                </a>
              </li>
              <li className="list-item">
                <a
                  href="#Teppich und Polster Reinigung"
                  className="gebudereinigung-link02"
                >
                  <span>Teppich und Polster Reinigung</span>
                  <br></br>
                </a>
              </li>
              <li className="list-item">
                <a
                  href="#Solaranlagenreinigung"
                  className="gebudereinigung-link03"
                >
                  <span>Solaranlagenreinigung</span>
                  <br></br>
                </a>
              </li>
            </ul>
          </div>
          <div className="">
            <img
              alt="image"
              src="/1585937009-500w.jpg"
              className="gebudereinigung-image"
            />
          </div>
        </div>
      </div>
      <div id="Grundreinigung" className="gebudereinigung-grundreinigung">
        <div
          data-thq="slider"
          data-loop="true"
          data-autoplay="true"
          data-navigation="true"
          data-pagination="true"
          className="gebudereinigung-slider swiper"
        >
          <div data-thq="slider-wrapper" className="swiper-wrapper">
            <div
              data-thq="slider-slide"
              className="gebudereinigung-slider-slide swiper-slide"
            >
              <span className="gebudereinigung-text012">Vorher - Nachher</span>
            </div>
            <div
              data-thq="slider-slide"
              className="gebudereinigung-slider-slide01 swiper-slide"
            >
              <span className="gebudereinigung-text013">Büroreinigung</span>
            </div>
            <div
              data-thq="slider-slide"
              className="gebudereinigung-slider-slide02 swiper-slide"
            >
              <span className="gebudereinigung-text014">
                <span>So Nicht!</span>
                <br></br>
              </span>
            </div>
          </div>
          <div
            data-thq="slider-pagination"
            className="gebudereinigung-slider-pagination swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal"
          >
            <div
              data-thq="slider-pagination-bullet"
              className="swiper-pagination-bullet swiper-pagination-bullet-active"
            ></div>
            <div
              data-thq="slider-pagination-bullet"
              className="swiper-pagination-bullet"
            ></div>
            <div
              data-thq="slider-pagination-bullet"
              className="swiper-pagination-bullet"
            ></div>
            <div
              data-thq="slider-pagination-bullet"
              className="swiper-pagination-bullet"
            ></div>
          </div>
          <div
            data-thq="slider-button-prev"
            className="swiper-button-prev"
          ></div>
          <div
            data-thq="slider-button-next"
            className="swiper-button-next"
          ></div>
        </div>
        <div className="flex-container">
          <span className="gebudereinigung-text011">Grundreinigung</span>
          <span>
            <br></br>
            <span>
              Das Ziel der Grundreinigungen ist in erster Linie eine intensive
              Reinigung von Oberflächen und tiefsitzenden Verschmutzungen, die
              neben der Unterhaltsreinigung in größeren Zeitabständen
              durchgeführt wird. Über Jahre sammelt sich Schmutz an Böden,
              Wänden, Möbeln und Oberflächen an, die eine besondere Reinigung
              erfordern, um den Wert wiederherzustellen bzw. zu gewährleisten.
              Der Fokus bei unserer Grundreinigung liegt auf der guten
              Organisation und unserer Erfahrung bei dem Einsatz von speziellen
              Techniken und Reinigungsgeräten, um das beste Ergebnis zu
              erzielen.
            </span>
            <br></br>
            <br></br>
            <span>
              Nach einer Grundreinigung bietet sich eine wiederkehrende
              Reinigung an, um die Verschmutzungen, die sich im Alltag
              angesammelt haben zu entfernen.
            </span>
            <br></br>
            <span>
              Die Erneuerung von Pflegemitteln spielt bei der Grundreinigung
              auch eine große Rolle, um den Schutz und die Versiegelung von
              Flächen zu gewährleisten.
            </span>
            <br></br>
            <br></br>
            <span>
              Unsere Grundreinigung bieten wir auch für eine Bauendreinigung,
              nach Renovierungsarbeiten und für sanitäre Einrichtungen an. Die
              Dienstleistung kann von privater Seite und von Gewerbetreibenden
              gebucht werden.
            </span>
            <br></br>
            <br></br>
            <span>
              Unsere Mitarbeiter sind explizit für Desinfektionsmaßnahmen
              geschult.
            </span>
            <br></br>
            <br></br>
            <span>
              Wir bieten Wartungsverträge für regelmäßige Reinigung, aber auch
              einzelne intensiv Grundreinigungen an.
            </span>
            <br></br>
            <br></br>
          </span>

          <span className="gebudereinigung-text037">
            Unser Angebot im Überblick:
          </span>
          <ul className="gebudereinigung-ul1 list">
            <li className="list-item">
              <span>Haushaltsreinigung</span>
            </li>
            <li className="list-item">
              <span>Büroreinigung</span>
            </li>
            <li className="list-item">
              <span>Baustellenreinigung</span>
            </li>
            <li className="list-item">
              <span>Küchenreinigung</span>
            </li>
            <li className="list-item">
              <span>Sanitäre Reinigung</span>
            </li>
          </ul>
        </div>
      </div>
      <div id="Glasreinigung" className="gebudereinigung-glasreinigung">
        <div
          data-thq="slider"
          data-loop="true"
          data-autoplay="true"
          data-navigation="true"
          data-pagination="true"
          className="gebudereinigung-slider1 swiper"
        >
          <div data-thq="slider-wrapper" className="swiper-wrapper">
            <div className="swiper-slide" data-thq="slider-slide">
              <div className="gebudereinigung-slider-slide04 gebudereinigungSlides">
                <span className="gebudereinigung-text044">Glasreinigung</span>
              </div>
            </div>

            <div className="swiper-slide" data-thq="slider-slide">
              <div className="gebudereinigung-slider-slide05 gebudereinigungSlides">
                <span className="gebudereinigung-text045">
                  <span>Durchblick</span>
                  <br></br>
                </span>
              </div>
            </div>

            <div className="swiper-slide" data-thq="slider-slide">
              <div className="gebudereinigung-slider-slide06 gebudereinigungSlides">
                <span className="gebudereinigung-text048">
                  Fensterreinigung mit Hebebühne
                </span>
              </div>
            </div>
          </div>
          <div
            data-thq="slider-pagination"
            className="gebudereinigung-slider-pagination1 swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal"
          >
            <div
              data-thq="slider-pagination-bullet"
              className="swiper-pagination-bullet swiper-pagination-bullet-active"
            ></div>
            <div
              data-thq="slider-pagination-bullet"
              className="swiper-pagination-bullet"
            ></div>
            <div
              data-thq="slider-pagination-bullet"
              className="swiper-pagination-bullet"
            ></div>
          </div>
          <div
            data-thq="slider-button-prev"
            className="swiper-button-prev"
          ></div>
          <div
            data-thq="slider-button-next"
            className="swiper-button-next"
          ></div>
        </div>
        <div className="flex-container">
          <span className="gebudereinigung-text043">Glasreinigung</span>
          <span>
            <br></br>
            <span>
              Glasflächen sind oftmals starken Umwelt-, und
              Witterungsverhältnissen ausgesetzt. Das führt dazu, dass ohne
              regelmäßige Pflege ein unschöner Eindruck entsteht und sich Staub,
              Pollen und Moos festsetzen. Neben dem Glas, leiden besonders
              Rahmen und Rollläden darunter.
            </span>
            <br></br>
            <span>
              Deshalb ist es wichtig, eine fachgerechte und regelmäßige
              Reinigung vorzunehmen, um auch eine klare Sicht und eine
              einladende Optik zu ermöglichen.
            </span>
            <br></br>
            <br></br>
            <span>
              Unsere erfahrenen Fensterputzer stehen für kompromisslose Qualität
              und reinigen alle Arten von Fenstern und Glaskonstruktionen, frei
              von Staub, Schlieren und Wasserflecken. Die Reinigung von Rahmen
              und Jalousien gilt dabei als Selbstverständlichkeit. Auch in
              großen Höhen, bewahren wir die Balance und reinigen Dachfenster
              und große Fassaden mit einer sicheren Hebebühne oder Leiter.
            </span>
            <br></br>
            <br></br>
            <span>
              Insbesondere verwenden wir für die Reinigung ausschließlich
              professionelle Werkzeuge, um die Reinigung qualitativ hoch und
              ohne hohen Zeitaufwand durchführen zu können. Dabei verwenden wir
              3-fach gefiltertes Wasser (Reinwasser), das entsalzt ist.
            </span>
            <br></br>
            <br></br>
            <span>
              Wir bieten unsere Dienstleistung auch für Schaufensterreinigung,
              große Glasfassaden, innen Bereiche und private Haushalte an.
            </span>
            <br></br>
            <br></br>
            <span>
              Vereinbaren Sie einen Termin oder kontaktieren Sie uns. Wir bietet
              ihnen sowohl einmalige Komplettreinigungen als auch regelmäßige
              Glasreinigungen, flexibel und auf Ihre Gebäude zuschnitten an.
            </span>
            <br></br>
            <br></br>
          </span>
        </div>
      </div>
      <div
        id="Teppich und Polster Reinigung"
        className="gebudereinigung-teppich-und-polster-reinigung"
      >
        <img
          alt="image"
          src="/image%20(1)-300h.jpg"
          className="gebudereinigung-image1"
        />
        <div className="flex-container">
          <span className="gebudereinigung-text068">
            Teppich und Polster Reinigung
          </span>

          <span className="gebudereinigung-text069">
            <span>
              Teppiche sind dem täglichen Gebrauch ausgesetzt und werden dadurch
              enorm beansprucht. Schnell sammelt sich unscheinbarer Staub,
              Milben und Bakterien in Teppichen und Bodenbelägen an, was nicht
              nur dem Teppich schadet, sondern auch ein Gesundheitsrisiko
              darstellt. Aber auch sichtbare Flecken, können den Erhalt des
              Teppichs und dessen Farbe beeinträchtigen und machen keinen
              schönen Eindruck.
            </span>
            <br></br>
            <br></br>
            <span>
              Einzelne Teppiche oder fest-verlegte Bodenbeläge reinigen wir
              schnell und gründlich und verwenden dabei umweltfreundliches und
              unbedenkliches Reinigungsmittel.
            </span>
            <br></br>
            <br></br>
            <span>
              Zusätzlich bieten wir die Reinigung von Sitzflächen und
              Polstermöbel an.
            </span>
            <br></br>
            <br></br>
            <span>
              Die Couch im privaten Wohnzimmer, oder Sitzmöglichkeiten und
              Polsterbänke in Restaurants sollen gemütlich sein und zum
              Hinsitzen einladen.
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
          </span>
        </div>
      </div>
      <div
        id="Solaranlagenreinigung"
        className="gebudereinigung-solaranlagenreinigung"
      >
        <div
          data-thq="slider"
          data-loop="true"
          data-autoplay="true"
          data-navigation="true"
          data-pagination="true"
          className="gebudereinigung-slider2 swiper"
        >
          <div data-thq="slider-wrapper" className="swiper-wrapper">
            <div
              data-thq="slider-slide"
              className="gebudereinigung-slider-slide07 swiper-slide"
            >
              <span className="gebudereinigung-text081">
                Industrielle Solaranlagen
              </span>
            </div>
            <div
              data-thq="slider-slide"
              className="gebudereinigung-slider-slide08 swiper-slide"
            >
              <span className="gebudereinigung-text082">
                Private Solaranlagen
              </span>
            </div>
            <div
              data-thq="slider-slide"
              className="gebudereinigung-slider-slide09 swiper-slide"
            >
              <span className="gebudereinigung-text083">
                HydroPower: Hier für Fenster und Solaranlagen
              </span>
            </div>
          </div>
          <div
            data-thq="slider-pagination"
            className="gebudereinigung-slider-pagination2 swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal"
          >
            <div
              data-thq="slider-pagination-bullet"
              className="swiper-pagination-bullet swiper-pagination-bullet-active"
            ></div>
            <div
              data-thq="slider-pagination-bullet"
              className="swiper-pagination-bullet"
            ></div>
            <div
              data-thq="slider-pagination-bullet"
              className="swiper-pagination-bullet"
            ></div>
          </div>
          <div
            data-thq="slider-button-prev"
            className="swiper-button-prev"
          ></div>
          <div
            data-thq="slider-button-next"
            className="swiper-button-next"
          ></div>
        </div>
        <div className="flex-container">
          <span className="gebudereinigung-text080">Solaranlagenreinigung</span>

          <span className="gebudereinigung-text084">
            <span>
              Die meisten Solaranlagen befinden sich auf den Dächern der Häuser,
              bei der eine Reinigung höchste Vorsicht vor Verletzungen oder dem
              Abstürzen voraussetzt. Verschmutzungen auf Ihrer Solaranlage
              beeinträchtigen den Energieertrag und können schnell zu Schäden an
              der empfindlichen Oberfläche führen. Staub und Pollen gelten als
              einfache Verschmutzungen und werden durch den
              Selbstreinigungseffekt mit Hilfe des Regenwassers abgedeckt. Bei
              starken Verunreinigungen greifen wir der Natur unter die Arme, da
              diese mit einfachem Regenwasser nicht zu reinigen sind. Dies ist
              der gleiche Effekt, der auch bei Windschutzscheiben von Autos
              hervorkommt. Die Schreibe wirkt auf den ersten Blick sauber,
              allerdings bleibt die Verschmutzung kleben.
            </span>
            <br></br>
            <span>
              Wichtig bei der Reinigung ist die richtige Vorgehensweise und das
              richtige Reinigungsmittel, da es ansonsten zu Beschädigungen
              führen kann.
            </span>
            <br></br>
            <br></br>
            <span>
              Eine Reinigung kann bis zu 30% Leistungssteigerung erbringen. Das
              Ergebnis kann direkt auf Ihrer Solaranlage abgelesen werden. Wir
              führen Reinigungen mit professionellem Equipment und Hebebühnen
              durch.
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
          </span>
        </div>
      </div>
      <div className="home-header">
        <header data-thq="thq-navbar" className="home-navbar-interactive">
          <div className="logoHeaderContainer">
            <div className="home-container01">
              <Link to="/" className="home-navlink">
                <img
                  alt="image"
                  src="/1585861357%20(2)-1500h.png"
                  className="home-image"
                />
              </Link>
            </div>
            <span className="home-text">Dienstleistungen Südwest</span>
          </div>
          <div className="home-container02">
            <div data-thq="thq-navbar-nav" className="home-desktop-menu">
              <nav className="home-links">
                <a href="#Home" className="home-link">
                  Home
                </a>
                <a
                  href="https://dienstleistungen-suedwest.de/#Dienstleistungen"
                  className="home-link1"
                >
                  Dienstleistungen
                </a>
                <a
                  href="https://dienstleistungen-suedwest.de/#Referenzen"
                  className="home-link2"
                >
                  Referenzen
                </a>
                <a
                  href="https://dienstleistungen-suedwest.de/#Über%20uns"
                  className="home-link3"
                >
                  Über uns
                </a>
                <a
                  href="https://dienstleistungen-suedwest.de/#Kontakt"
                  className="home-link4"
                >
                  Kontakt
                </a>
              </nav>
              <div className="home-buttons">
                <a
                  href="https://jobs.dienstleistungen"
                  className="home-link5 button"
                >
                  <span className="home-text001">
                    <span>Stellenauschreibung</span>
                    <br></br>
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div
            data-thq="thq-burger-menu"
            className="home-burger-menu"
            onClick={() => handleNavOpen()}
          >
            <svg viewBox="0 0 1024 1024" className="home-icon">
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>

          {openNav ? (
            <div data-thq="thq-mobile-menu" className="home-mobile-menu">
              <div className="home-nav">
                <div className="home-top">
                  <img
                    alt="image"
                    src="/1585861357%20(2)-1500h.png"
                    className="home-logo"
                  />
                  <div
                    data-thq="thq-close-menu"
                    className="home-close-menu"
                    onClick={() => handleNavOpen()}
                  >
                    <svg viewBox="0 0 1024 1024" className="home-icon02">
                      <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                    </svg>
                  </div>
                </div>
                <nav className="home-links1">
                  <a
                    href="/"
                    onClick={() => handleNavOpen()}
                    className="home-link"
                  >
                    Home
                  </a>
                  <a
                    href="https://dienstleistungen-suedwest.de/#Dienstleistungen"
                    onClick={() => handleNavOpen()}
                    className="home-link1"
                  >
                    Dienstleistungen
                  </a>
                  <a
                    href="https://dienstleistungen-suedwest.de/#Referenzen"
                    onClick={() => handleNavOpen()}
                    className="home-link2"
                  >
                    Referenzen
                  </a>
                  <a
                    href="https://dienstleistungen-suedwest.de/#Über%20uns"
                    onClick={() => handleNavOpen()}
                    className="home-link3"
                  >
                    Über uns
                  </a>
                  <a
                    href="https://dienstleistungen-suedwest.de/#Kontakt"
                    onClick={() => handleNavOpen()}
                    className="home-link4"
                  >
                    Kontakt
                  </a>
                </nav>
                <div className="home-buttons">
                  <a
                    href="https://jobs.dienstleistungen"
                    className="home-link5 button"
                  >
                    <span className="home-text001">
                      <span>Stellenauschreibung</span>
                      <br></br>
                    </span>
                  </a>
                </div>
              </div>
              <div className="nav-socials">
                <svg
                  viewBox="0 0 950.8571428571428 1024"
                  className="home-icon04"
                >
                  <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                </svg>
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  className="home-icon06"
                >
                  <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                </svg>
                <svg
                  viewBox="0 0 602.2582857142856 1024"
                  className="home-icon08"
                >
                  <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
                </svg>
              </div>
            </div>
          ) : null}
        </header>
      </div>

      <footer className="home-footer">
        <img
          alt="logo"
          src="/1585861357%20(2)-1500h.png"
          className="home-image12"
        />
        <Link to="/impressum" className="home-navlink6">
          <span>Impressum</span>
          <br></br>
        </Link>
        <Link to="/datenschutz" className="home-navlink6">
          <span>Datenschutz</span>
          <br></br>
        </Link>
        <div className="home-icon-group1">
          <a
            href="https://www.instagram.com/dienstleistungen_suedwest/"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link7"
          >
            <svg viewBox="0 0 877.7142857142857 1024" className="home-icon10">
              <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
            </svg>
          </a>
        </div>
      </footer>
    </div>
  );
};

export default Gebudereinigung;
